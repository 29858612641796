.login {
    margin: 10px;
    max-width: 90%;
}

.error {
    color: red;
}

.p-rules {
    font-size: 10px;
}